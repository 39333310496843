import Swal from 'sweetalert2';
import { fetchWithToken } from '../helpers/fetch';
import { prepareEvents } from '../helpers/prepareEvents';
import { types } from '../types/types';

export const eventStartAddNew = ( event ) => {
    return async ( dispatch, getState ) => {

        const { uid, name } = getState().auth;

        try {

            const resp = await fetchWithToken ( 'events', event , 'POST' );

            const body = await resp.json();
    
            if ( body.ok ) {
                event.id = body.event.id;
                // Para obtener los datos del usuario, lo rescatamos del redux con getsate
                event.user = {
                    _id : uid,
                    name
                }

                //console.log( event ); 

                dispatch ( eventAddNew ( event )); 

            } else {
                Swal.fire ( 'Error', body.msg, 'error');
            }
            
        } catch (error) {
            console.log( error );
            
        }

 

    }

} 


// La siguinete accion no la vamos a llamar directemente cuando hagamos click en save, por lo que le sacamos export 
const eventAddNew = ( event )=> ({
    type: types.eventAddNew,
    payload: event
});

export const eventSetActive = ( event )=> ({
    type: types.eventSetActive,
    payload: event
});

export const eventClearActiveEvent = ()=> ({
    type: types.eventClearActiveEvent
});





export const eventStartLoading = () => {
    return async ( dispatch ) => {
        // const { uid, name } = getState().auth;
        try {
            const resp = await fetchWithToken ( 'events' );
            const body = await resp.json();
            // console.log ( body );
            if ( body.ok ) {

                const events = prepareEvents (body.events) ;
                //event.id = body.event.id;
                // Para obtener los datos del usuario, lo rescatamos del redux con getsate
                // event.user = {
                //     _id : uid,
                //     name
                // }
                // console.log( events ); 
                dispatch ( eventLoaded ( events )); 
            } else {
                Swal.fire ( 'Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            
        }
    }
}

const eventLoaded  = ( events ) => ({
    type: types.eventLoaded,
    payload: events
});


export const eventStartUpdate = ( event ) => {
    return async ( dispatch ) => {
        try {
            //console.log ( event.id );
            const resp = await fetchWithToken ( `events/${ event.id }` , event , 'PUT' );
            const body = await resp.json();    
            
            if ( body.ok ) {
                dispatch ( eventUpdated ( event )); 
            } else {
                console.log( body.msg );
                Swal.fire ( 'Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
        }
    }
} 

const eventUpdated = ( event )=> ({
    type: types.eventUpdated,
    payload: event
});

export const eventStartDelete = () => {
    return async ( dispatch, getState ) => {

        const { id } = getState().calendar.activeEvent;

        try {
            //console.log ( event.id );
            const resp = await fetchWithToken ( `events/${  id }` , {} , 'DELETE' );
            const body = await resp.json();    
            
            if ( body.ok ) {
                dispatch ( eventDeleted ()); 
            } else {
                console.log( body.msg );
                Swal.fire ( 'Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
        }
    }
}

const eventDeleted = ( )=> ({
    type: types.eventDeleted
});

export const eventLogout =() => ({
    type: types.eventLogout
});