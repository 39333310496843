import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers/rootReduce';

// import { authReducer } from '../reducers/authReducer';
// import { notesReducer } from '../reducers/notesReducer';
// import { uiReducer } from '../reducers/uiReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// const reducers = combineReducers({
//     ui : uiReducer,
//     rootReducer: rootReducer
// });


export const store = createStore ( 
    rootReducer,
    composeEnhancers (
        applyMiddleware ( thunk )
    )     
);

