
import React from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { startLogin, startRegister } from '../../actions/auth';
import { useForm } from '../../hooks/useForm';
import './login.css';

export const LoginScreen = () => {

    const dispatch = useDispatch();

    // const { loading } = useSelector( state => state.ui );

    // const initialLoginForm = {
    //     lEmail: 'eduardo@gmail.com',
    //     lPassword: '123456'
    // };

    // const initialRegisterForm = {
    //     rName: 'Daniela',
    //     rEmail: 'daniela@gmail.com',
    //     rPassword1: '123456',
    //     rPassword2: '123456'
    // };

    const initialLoginForm = {
        lEmail: '',
        lPassword: ''
    };

    const initialRegisterForm = {
        rName: '',
        rEmail: '',
        rPassword1: '',
        rPassword2: ''
    };


    const [ formLoginValues, handleLoginInputChange ] = useForm( initialLoginForm );

    const [ formRegisterValues, handleRegisterInputChange ] = useForm( initialRegisterForm );

    const { lEmail, lPassword } = formLoginValues;

    const { rName, rEmail, rPassword1, rPassword2 } = formRegisterValues;

    const handleLogin = (e) => {
        //console.log ('Login');
        e.preventDefault();

        // console.log ( formLoginValues );
        // dispatch ( login (12345, 'Eduardo' ) );

        dispatch ( startLogin ( lEmail, lPassword ) );
       
        //console.log( email, password ); 

    }

    const handleRegister = (e) => {
        //console.log ('Login');
        e.preventDefault();

        if ( rPassword1 !== rPassword2 ){

            return Swal.fire('Error', 'Las contraseñas deben ser iguales', 'error');
        } 

        // console.log ( formRegisterValues );
        // dispatch ( login (12345, 'Eduardo' ) );

        dispatch ( startRegister ( rName, rEmail, rPassword1 ) );
        //console.log( email, password ); 
    }


    return (
        <div className="container login-container">
            <div className="row">
                <div className="col-md-6 login-form-1">
                    <h3>Ingreso</h3>
                    <form
                        onSubmit = { handleLogin }
                    >
                        <div className="form-group">
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Correo"
                                name="lEmail"
                                autoComplete="off"
                                value = { lEmail }
                                onChange = { handleLoginInputChange }
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Contraseña"
                                autoComplete="off"
                                name="lPassword"
                                value = { lPassword }
                                onChange = { handleLoginInputChange }
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="submit"
                                className="btnSubmit"
                                value="Login" 
                            />
                        </div>
                    </form>
                </div>

                <div className="col-md-6 login-form-2">
                    <h3>Registro</h3>
                    <form
                        onSubmit = { handleRegister }
                    >
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nombre"
                                name="rName"
                                autoComplete="off"
                                value = { rName }
                                onChange = { handleRegisterInputChange }
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Correo"
                                name="rEmail"
                                autoComplete="off"
                                value = { rEmail }
                                onChange = { handleRegisterInputChange }
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Contraseña"
                                name="rPassword1"
                                autoComplete="off"
                                value = { rPassword1 }
                                onChange = { handleRegisterInputChange } 
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Repita la contraseña"
                                name="rPassword2"
                                autoComplete="off"
                                value = { rPassword2 }
                                onChange = { handleRegisterInputChange }  
                            />
                        </div>

                        <div className="form-group">
                            <input 
                                type="submit" 
                                className="btnSubmit" 
                                value="Crear cuenta" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}