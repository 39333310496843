export const types = {
    uiOpenModal: '[ui] Open modal',
    uiCloseModal: '[ui] Close modal',

    eventSetActive: '[event] Set active',
    eventLogout: '[event] logout',

    eventStartAddNew: '[event] Add start new',
    eventAddNew: '[event] Add new',
    eventClearActiveEvent: '[event] Clear active event',
    eventUpdated: '[event] Event updated',
    eventDeleted: '[event] Event deleted',

    eventLoaded: '[event] Event loaded',


    // authChecking: '[auth] Checking login state',
    authCheckingFinish:  '[auth] Finish checking login state',
    authStartLogin:  '[auth] Start login',
    authLogin:  '[auth] Login',
    authStartRegister:  '[auth] Start register',
    authStartTokenRenew:  '[auth] Start token renew',
    authLogout:  '[auth] Logout',
} 