import Swal from 'sweetalert2';
import { fetchWithoutToken, fetchWithToken } from '../helpers/fetch';
import { types } from '../types/types';
import { eventLogout } from './events';

export const startLogin = ( email, password ) => {
    // y como es una tarea asincrona voy a usar un return de una funcion fecha y gracias al thunk esto se va a volver a disparar,
    // recordemos que si no fuera asincrono, entonces no haria falta este return.  Y Thunk dispone el dispatch
    return async( dispatch ) => {

        //console.log ( email, password );

        const resp = await fetchWithoutToken ( 'auth', { email, password }, 'POST' );

        const body = await resp.json();

        if ( body.ok ) {
            localStorage.setItem ( 'token', body.token );
            localStorage.setItem ( 'token-init-date', new Date().getTime() );

                dispatch ( login ({
                        uid: body.uid,
                        name: body.name
                    }) 
                ); 

        } else {
            Swal.fire ( 'Error', body.msg, 'error');
        }

        //console.log ( body );
        // dispatch ( login ({
        //         uid: body.uid,
        //         name: body.name
        //     }) 
        // ); 
        
    }
} 

export const startRegister = ( name, email, password ) => {
    return async ( dispatch ) => {

        const resp = await fetchWithoutToken ( 'auth/new', { name, email, password }, 'POST' );

        const body = await resp.json();

        if ( body.ok ) {
            localStorage.setItem ( 'token', body.token );
            localStorage.setItem ( 'token-init-date', new Date().getTime() );

            dispatch ( login ({
                    uid: body.uid,
                    name: body.name
                }) 
            ); 

        } else {
            Swal.fire ( 'Error', body.msg, 'error');
        }

        
    }
}

export const startChecking = () => {
    return async ( dispatch ) => {
        const resp = await fetchWithToken ( 'auth/renew' );

        const body = await resp.json();

        // console.log ( body );


        if ( body.ok ) {
            localStorage.setItem ( 'token', body.token );
            localStorage.setItem ( 'token-init-date', new Date().getTime() );

            dispatch ( login ({
                    uid: body.uid,
                    name: body.name
                }) 
            ); 

        } else {
            // Swal.fire ( 'Error', body.msg, 'error');
            return dispatch ( checkingFinish ()); 
        }
    }
}

export const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});

const checkingFinish = () => ({
    type: types.authCheckingFinish
});


export const startLogout = () => {
    return  ( dispatch ) => {
        
        localStorage.clear();

        dispatch ( eventLogout() );
   
        dispatch ( logout ());

        // Aqui purgar info del usuario
        // dispatch ( noteLogout());


    }
}

export const logout = () => ({
    type: types.authLogout
})
