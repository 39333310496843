
import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { Navbar } from '../ui/Navbar';
import { messages } from '../../helpers/calendar-messages-es';
import { CalendarEvent } from './CalendarEvent';
import { CalendarModal } from './CalendarModal';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import { uiOpenModal } from '../../actions/ui';
import { eventClearActiveEvent, eventSetActive, eventStartLoading } from '../../actions/events';
import { AddNewFab } from '../ui/AddNewFab';
import { DeleteEventFab } from '../ui/DeleteEventFab';


moment.locale('es');

const localizer = momentLocalizer(moment);

// const events2 = [{
//     id: new Date().getTime(),
//     title: 'Reunion con el Jefe',
//     start: moment().toDate(),
//     end: moment().add(2, 'hours').toDate(),
//     bgcolor: '#fafafa',
//     user: {
//         _id: '12',
//         name: 'Daniela'
//     }

// }];


export const CalendarScreen = () => {


    const dispatch = useDispatch();

    // leer del store los eventos
    const { events, activeEvent } = useSelector( state => state.calendar );

    // leer datos del usuario del store
    const { uid } = useSelector( state => state.auth );

    // console.log( "events" );
    // console.log( events );
    // console.log( "events2" );
    // console.log( events2 );

    // console.log( "activeEvent" );
    // console.log( activeEvent );

    const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month');

    useEffect(() => {
        dispatch ( eventStartLoading() )
    }, [dispatch]);

    const onDobleClick = (e) => {
        //console.log( e );
        //console.log('Abrir Modal');
       dispatch ( uiOpenModal() );
    }

    const onSelectEvent = (e) => {
        //console.log( e );
        dispatch ( eventSetActive(e) );
        //dispatch ( uiOpenModal() );
    }

    const onViewChange = (e) => {
        //console.log( e );
        setLastView (e);
        localStorage.setItem('lastView', e);
    }

    const onSelectSlot = ( e ) => {
        //console.log( e );
        //TODO: podriamos obtner la hora donde se hizo click y pasarsela al formaulario 
        dispatch ( eventClearActiveEvent ()); 
    }

    const eventStyleGetter = ( event, start, end, isSelected ) => {
        // console.log(event, start, end, isSelected);

        //console.log( event );

        // Mediente un ternario vamos preguntar si uid == user.id del evento 
        const style = {
            backgroundColor: (uid == event.user._id ) ? '#367CF7' : '#465660',
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
            color: 'white'
        };

        return {
            style
        }

    } 

    return (
        <div className='calendar-screen'>
            <Navbar />
            <Calendar
                localizer ={  localizer }
                events = { events }
                startAccessor = "start"
                endAccessor = "end"
                style={{ height: 500 }}
                messages = { messages }
                eventPropGetter = { eventStyleGetter }
                onDoubleClickEvent = { onDobleClick }
                onSelectEvent = { onSelectEvent }
                onView = { onViewChange }
                onSelectSlot = { onSelectSlot }
                selectable = { true }
                view = { lastView }
                components = { { event : CalendarEvent } }
            />

            <AddNewFab />

            {
                    ( activeEvent )
                        ? ( <DeleteEventFab />)
                        : ( null )
            } 

            <CalendarModal />

            
        </div>
    )
}
