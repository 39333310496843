
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Modal from 'react-modal';
import DateTimePicker from 'react-datetime-picker';
import Swal from 'sweetalert2';

import { uiCloseModal } from '../../actions/ui';
import { eventClearActiveEvent, eventStartAddNew, eventStartUpdate } from '../../actions/events';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');  
// Si nos vamos a nuestro index.html del public vemos que tenemos root

// Pare resolver problema en test : react-modal: No elements were found for selector #root. Modal.setAppElement('#root');

if ( process.env.NODE_ENV !== 'test' ) {
    Modal.setAppElement('#root');  
}
const now = moment().minutes(0).seconds(0).add(1, 'hours');
// console.log("now:");
// console.log(now);
// const time = moment().toDate();
// console.log("time:");
// console.log(time);

const nowPlusOne = now.clone().add(1, 'hours');

const initEvent = {
    title: '',
    notes: '',
    start: now.toDate(),
    end: nowPlusOne.toDate()
};

export const CalendarModal = () => {

    // const [isOpen, setIsOpen] = useState(true);


    const { modalOpen } = useSelector( state => state.ui );
    const { activeEvent } = useSelector( state => state.calendar );

    const dispatch = useDispatch();

    const [ dateStart, setDateStart ] = useState(now.toDate());
    const [ dateEnd, setDateEnd ] = useState(nowPlusOne.toDate());
    const [ titleValid, setTitleValid ] = useState(true);

    const [ formValues, setFormValues ] = useState( initEvent );

    const { notes, title, start, end } = formValues;

    useEffect(() => {

        if ( activeEvent ){
            setFormValues ( activeEvent );
        } else {
            setFormValues ( initEvent );
        }
        //console.log(activeEvent);
    }, [ activeEvent, setFormValues ])


    const hanldeInputChange = ({ target }) => {
        setFormValues ( {
            ...formValues,
            [ target.name ] : target.value
        } );
    }

    const closeModal = () => {
        // console.log('cerrando...');
        // setIsOpen ( false );
        //TODO: Cerrar el Modal
        //console.log('Cerrar modal');
        dispatch ( uiCloseModal() );
        dispatch ( eventClearActiveEvent() ); 
        setFormValues( initEvent );
    }

    const handleStartDateChange = ( e ) => {
        console.log( "handleStartDateChange:" );
        console.log( e );
        setDateStart( e) ;
        setFormValues({
            ...formValues,
            start: e
        });
    }

    const handleEndDateChange = ( e ) => {
        // console.log( "handleEndDateChange:" );
        // console.log( e );
        //console.log( e );
        setDateEnd( e ) ;
        setFormValues({
            ...formValues,
            end: e
        });
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const momentStart = moment( start );
        const momentEnd = moment( end );

        if ( momentStart.isSameOrAfter ( momentEnd ) ) {
            return Swal.fire ('Error', 'La fecha fin debe ser mayor a la fecha inicio', 'error');
            //console.log ('Fehca 2 debe de ser mayor al fecha 1 ');
        }

        if ( title.trim().length < 2 )
        {
            return setTitleValid ( false );
        }

        //Realizar grabación
        //console.log(formValues);
        
        if ( activeEvent ) {

            dispatch ( 
                // eventUpdated( formValues )
                eventStartUpdate( formValues )
            );
            
        } else {
            dispatch ( 
                eventStartAddNew( formValues )
                // eventAddNew( {
                //     ...formValues,
                //     id: new Date().getTime(),
                //     user: {
                //         _id: '123',
                //         name: 'Eduardo'
                //     }
                // }) 
            );

        }
        
        

        setTitleValid ( true );

        closeModal();

        
    }


    return (
        <Modal
            isOpen={ modalOpen }
            // isOpen={ isOpen }
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            // contentLabel="Example Modal"
            className="modal" 
            closeTimeoutMS={ 200 }
            overlayClassName="modal-fondo"
            ariaHideApp={ !process.env.NODE_ENV === 'test' }
        >
            <h1> { ( activeEvent ) ? 'Editar evento' :  'Nuevo evento' } </h1>

            <hr />
            <form 
                className="container"
                onSubmit={ handleSubmitForm}
            >

                <div className="form-group">
                    <label>Fecha y hora inicio</label>
                    {/* <input className="form-control" placeholder="Fecha inicio" /> */}
                    <DateTimePicker
                        onChange={ handleStartDateChange }
                        value={ dateStart }
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label>Fecha y hora fin</label>
                    {/* <input className="form-control" placeholder="Fecha fin" /> */}
                    <DateTimePicker
                        onChange={ handleEndDateChange}
                        value = { dateEnd }
                        minDate = { dateStart }
                        className="form-control"
                    />
                </div>

                <hr />
                <div className="form-group">
                    <label>Titulo y notas</label>
                    <input 
                        type="text" 
                        className={ `form-control  ${ !titleValid && 'is-invalid'}`}
                        placeholder="Título del evento"
                        name="title"
                        autoComplete="off"
                        value = { title }
                        onChange={ hanldeInputChange }
                    />
                    <small id="emailHelp" className="form-text text-muted">Una descripción corta</small>
                </div>

                <div className="form-group">
                    <textarea 
                        type="text" 
                        className="form-control"
                        placeholder="Notas"
                        rows="5"
                        name="notes"
                        value = { notes }
                        onChange={ hanldeInputChange }
                    ></textarea>
                    <small id="emailHelp" className="form-text text-muted">Información adicional</small>
                </div>

                <button
                    type="submit"
                    className="btn btn-outline-primary btn-block"
                >
                    <i className="far fa-save"></i>
                    <span> Guardar</span>
                </button>

            </form>      
        </Modal>    

    )
}
