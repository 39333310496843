import { types } from "../types/types";
// import moment from "moment";

// {
//     id: 'dsfsdfdfsdfd',
//     title: 'Reunion con el Jefe',
//     // start: "2022-01-11T16:00:00.000Z", //moment().toDate(),
//     // end: "2022-01-11T18:00:00.000Z", //moment().add(2, 'hours').toDate(),
//     start: moment().toDate(),
//     end: moment().add(2, 'hours').toDate(),
//     user: {
//         _id: '12',
//         name: 'Daniela'
//     }

// }

const initialState = {
    events:[],
    activeEvent: null    
};

export const calendarReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case types.eventSetActive:
            
            return {
                ...state,
                activeEvent: action.payload
            };

        case types.eventAddNew:

            // console.log('state.events');
            // console.log(state.events);

            // console.log('action.payload');
            // console.log(action.payload);
        
            return {
                ...state,
                events: [ ...state.events, action.payload  ]
            }     
        case types.eventClearActiveEvent:
            
                return {
                    ...state,
                    activeEvent: null
                };
        case types.eventUpdated:
    
            return {
                ...state,
                events: state.events.map(
                    event => event.id === action.payload.id
                        ? action.payload
                        : event
                )
            };

         case types.eventDeleted:
            return {
                ...state,
                events: state.events.filter ( event => event.id !== state.activeEvent.id ),
                activeEvent: null
            }
        case types.eventLoaded:
            return {
                ...state,
                events: [ ...action.payload ]
            } 
        case types.eventLogout:
                return {
                    ...initialState
                }
                                
                    
        default:
            return state;
    }

}

