
import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store/store';
import { AppRouter } from './routers/AppRouter';




export const CalendarApp = () => {
    return (
        <Provider store={ store }>
            <AppRouter />
            {
               /*
                    // exact /login hacia el componente de LoginScreen
                    // exact /   hacia componente - CalendarScreen

               */ 
            }
        </Provider>
    )
}
